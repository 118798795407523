'use client';
import type { Database } from '@/types/supabase';
import { type SupaAuthedClient } from './supaUtils';
import { createBrowserClient } from '@supabase/ssr';
import { getEnvironmentVariable } from './envVars';

/**
 * Get back a form of the Supabase client that can be used in client components.
 */
export function getClientComponentClient(): SupaAuthedClient {
    const client = createBrowserClient<Database>(
        getEnvironmentVariable(process.env.NEXT_PUBLIC_SUPABASE_URL),
        getEnvironmentVariable(process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY)
    );

    return client as SupaAuthedClient;
}
