/**
 * This function is used to get environment variables from the process.env object.
 * It makes sure that the environment variable is defined and is a string.
 * @param name The name of the environment variable
 * @returns The value of the environment variable or throws an error if it is not defined or is not a string
 */
export const getEnvironmentVariable = <T extends string>(variable: T | undefined): T => {
    if (variable === undefined) {
        throw new Error(`Environment variable is not defined`);
    }

    if (typeof variable !== 'string' || variable.length === 0) {
        throw new Error(`Environment variable is not a string`);
    }
    return variable;
};
