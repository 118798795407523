globalThis["__sentryRewritesTunnelPath__"] = "/sentry";
globalThis["SENTRY_RELEASE"] = {"id":"TLmzFG-JKw5zvzPhaVv3e"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// import {
//     browserTracingIntegration,
//     init,
//     replayIntegration,
//     showReportDialog,
// } from '@sentry/nextjs';
import * as Sentry from '@sentry/nextjs';
import { supabaseIntegration } from '@supabase/sentry-js-integration';
import { getClientComponentClient } from './utils/clientSupaUtils';

Sentry.init({
    dsn: 'https://d03a1e589b267ed3b8f1b56469f3f69d@o4506020972986368.ingest.sentry.io/4506020974362624',
    ignoreErrors: [
        'ResizeObserver loop completed with undelivered notifications.',
        'ResizeObserver loop completed with undelivered notifications',
        'TypeError: Failed to fetch', // Got tired of all the RSC failed to prefetch errors
    ],
    // Adjust this value in production, or use tracesSampler for greater control
    enableTracing: false,
    // tracesSampleRate: 0.1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // Disable non-error replays entirely. They will be captured by posthog instead.
    replaysSessionSampleRate: 0,

    beforeSend(event) {
        if (event.request) {
            delete event.request.cookies;
            delete event.request.headers;
        }

        if (event.exception && event.level === 'fatal') {
            Sentry.showReportDialog({
                eventId: event.event_id,
                title: 'Uh oh! Report an error',
                subtitle: 'Help us fix the problem by filling out the form below.',
                labelName: 'Name',
                labelEmail: 'Email',
                labelComments: 'What happened?',
                labelClose: 'Close',
                labelSubmit: 'Send report',
                errorGeneric: 'Sorry, something went wrong.',
                errorFormEntry: 'Some fields are invalid.',
                successMessage: 'Thanks for your feedback!',
                onClose() {
                    location.reload();
                },
            });
        }
        return event;
    },

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
        Sentry.replayIntegration({
            // Additional Replay configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
            maskAllInputs: false,
        }),
        Sentry.captureConsoleIntegration({
            levels: ['error'],
        }),
        supabaseIntegration(getClientComponentClient(), Sentry, {
            tracing: true,
            breadcrumbs: true,
            errors: true,
        }),
        Sentry.browserTracingIntegration({
            shouldCreateSpanForRequest: (url) => {
                return !url.startsWith(`${process.env.NEXT_PUBLIC_SUPABASE_URL}/rest`);
            },
        }),
    ],

    enabled: process.env.NODE_ENV === 'production',
    environment: process.env.NODE_ENV,
});
